import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  squidexBearerToken: '',
};

export const squidexSlice = createSlice({
  name: 'squidex',
  initialState,
  reducers: {
    setBearerToken: (state, action) => {
      state.squidexBearerToken = action.payload;
    },
  },
});

export const { setBearerToken } = squidexSlice.actions;

export default squidexSlice.reducer;
